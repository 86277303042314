import LogRocket from 'logrocket';
import { AppProps } from 'next/app';
import { continueExistingLogRocketSession } from '@zola-helpers/client/dist/es/util/logrocket/logRocketUtils';
import { continueExistingSessionReplay } from '@zola-helpers/client/dist/es/util/mixpanel/sessionReplay';
import 'intersection-observer';
import { useEffect } from 'react';
import { enhancedContext } from '@zola/tracking-contracts/src/plugins/enhancedContext';
import { enhancedTraits } from '@zola/tracking-contracts/src/plugins/enhancedTraits';
import { mixpanelContext } from '@zola/tracking-contracts/src/plugins/mixpanelContext';

import 'styles/global/global.less';

// Initialize Segment before any other rendering
if (typeof window !== 'undefined' && window.analytics) {
  continueExistingSessionReplay();
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  window.analytics.register(enhancedContext, enhancedTraits(), mixpanelContext());
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  window.analytics.page();
}

export default function App({
  Component,
  pageProps,
  err,
}: AppProps & {
  err?: unknown;
}): JSX.Element {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.LogRocket = LogRocket;
      continueExistingLogRocketSession();
    }
  }, []);
  return <Component {...pageProps} err={err} />;
}
